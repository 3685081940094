import { RecurringBillingFrequency } from '../../services/hubspot/recurring-billing-frequency.enum.ts';
import { ExistingPhoneNumberTermination } from '../../services/order';

const billingFrequencyTerm: { [key in RecurringBillingFrequency]: string } = {
  annually: 'jaar',
  monthly: 'maand',
  one_time: 'eenmalig',
  quarterly: 'kwartaal',
};

const phoneNumberTerminationMessages: { [key in ExistingPhoneNumberTermination]: string } = {
  forced: 'Ja',
  no: 'Nee',
  yes: 'Ja, wanneer mogelijk',
};

export const OrderMessages = {
  title: 'Order',
  description:
    'Dit overzicht toont al jullie orders. ' +
    'Je kunt hier de status van de order volgen en de details van de order bekijken.',
  display: 'Order {order_number} - {connection_address}',
  search: 'Zoek op aansluitadres, klantnaam of ordernummer',
  visit: 'Bezoek order',

  conditions: {
    connection:
      'De aansluiting wordt aangebracht in de meterkast met een maximum van 10 meter inpandig. ' +
      'Exclusief speciale voorzieningen. ' +
      'Overige locaties en extra lengte tegen meerprijs.',
    sla:
      'De Service Level Agreement is onlosmakelijk onderdeel van deze verbindingsovereenkomst. ' +
      "Genoemde bedragen zijn in Euro's en exclusief BTW.",
    contract: {
      title: 'Contract',
      term: 'De minimale contractduur bedraagt {term} maanden, daarna wordt het contract met telkens 1 kalendermaand verlengd.',
      agreement: 'Ik ga akkoord met het contract en de looptijd van {term} maanden.',
    },
    approval:
      'De overeenkomst komt tot stand nadat Fore Freedom de aanvraag schriftelijk heeft bevestigd. ' +
      'Fore Freedom behoudt zich het recht voor aanvragen zonder opgaaf van reden te weigeren.',
    terms: {
      title: 'Algemene voorwaarden',
      description:
        'Op alle diensten zijn de algemene voorwaarden van toepassing. ' +
        'Door ondertekening verklaar je de algemene voorwaarden te hebben ontvangen, gelezen en daarmee akkoord te gaan.',
      agreement:
        'Fore Freedom B.V. verklaart haar algemene voorwaarden van toepassing op deze overeenkomst. ' +
        'Door het verzenden van deze order verklaar je de algemene voorwaarden te hebben ontvangen, gelezen en daarmee akkoord te gaan.',
    },
  },
  config: {
    text: 'De configuratie toont de IP adressen en/of NNI VLAN ID die je kan instellen op de klantapparatuur.',
    title: 'Configuratie',
    device: 'Apparaat',
    none: 'Geen configuratie beschikbaar',
    port: 'Poort',
    prefix: {
      ipAddresses: 'IP adres(sen): {ipAddresses}',
      netmask: 'Subnet mask: {netmask}',
      gateway: 'Gateway: {gateway}',
      dnsServers: 'DNS Servers: 185.69.206.27 185.69.206.35',
    },
    vid: 'NNI VLAN ID',
  },
  connectionCosts: 'Aansluitkosten',
  contacts: 'Contactpersonen',
  customer: {
    name: 'Klantnaam',
    voipText:
      'Selecteer hier eerst een vestiging van de eindklant uit KVK. ' +
      'Deze hebben wij nodig om het netnummer te bepalen. ' +
      'Zonder een KVK vestiging kunnen wij geen telefoonnummer aanvragen voor de klant bij de ACM.',
    title: 'Klant',
  },
  date: {
    createDate: 'Orderdatum',
    closeDate: 'Acceptatiedatum',
  },
  delayedActivation: {
    title: 'Uitgestelde activatie',
    text:
      'Als de verbinding niet z.s.m. na oplevering geactiveerd dient te worden, dan kunnen wij de activatie uitstellen. ' +
      ' Wij zullen de verbinding dan pas activeren en de facturatie starten op de gewenste activatiedatum. ',
    no: 'Nee, ik wil de verbinding z.s.m. opgeleverd en geactiveerd hebben',
    yes: 'Ja, ik wil de activatie uitstellen',
  },
  editReference: 'Referentie bewerken',
  oneTimeCosts: 'Eenmalige kosten',
  requestBundelingActive:
    'In dit gebied is een vraagbundeling actief. ' +
    'U kunt bestellen, echter bij te weinig animo is het mogelijk dat wij de bestelling annuleren.',
  selectedArticles: 'Geselecteerde artikelen',
  technicalContact: {
    text:
      'Voor het maken van installatie-afspraken hebben wij een contactpersoon van de klant nodig. ' +
      'De klantnaam vragen wij om communicatie over deze order snel en duidelijk te laten verlopen.',
    title: 'Technisch contact',
  },
  timeline: {
    text: 'Deze tijdlijn geeft een overzicht van de belangrijkst genomen stappen in het bestelproces.',
    title: 'Tijdlijn',
  },
  voip: {
    create: 'Bestel VoIP',
    callPlanOptions: {
      text: 'Selecteer hier de belplan opties die van toepassing zijn.',
      choiceMenu: 'Keuzemenu',
      forwarding: {
        title: 'Doorschakeling',
        numberOfRings: 'Aantal keer overgaan',
        phoneNumber: 'Doorschakelnummer',
      },
      openingHours: 'Openingstijden',
      queue: 'Wachtrij?',
      voicemail: 'Voicemail',
      comment: {
        choiceMenu:
          'Geef hier de structuur op die het keuzemenu moet bevatten. Submenu’s zijn mogelijk.',
        email: 'Geef hier het e-mailadres op waar de voicemail naar toe gestuurd moet worden.',
        openingHours:
          'Geef hier de openingstijden op, rekening houdend met bijvoorbeeld lunchpauzes, feestdagen, etc.',
      },
    },
    phoneNumber: {
      title: 'Telefoonnummer',
      add: 'Blok toevoegen',
      areaCode: {
        title: 'Netnummer',
        national: 'Nationaal (085)',
        regional: 'Regionaal',
      },
      block: 'Blok',
      currentProvider: {
        title: 'Huidige provider',
        address: 'Adres',
        name: 'Naam',
        clientNumber: 'Klantnummer',
      },
      existing: {
        text: 'Geef hier het aantal telefoonnummers met nummerbehoud op per blok.',
        title: 'Bestaande telefoonnummers (nummerbehoud)',
      },
      new: {
        text: 'Geef hier het aantal nieuwe telefoonnummers op per blok.',
        title: 'Nieuwe telefoonnummers',
      },
      termination: {
        title: 'Opzeggen?',
        ...phoneNumberTerminationMessages,
      },
    },
  },
  wizard: {
    error: {
      article: 'Er moet één artikel worden geselecteerd.',
      package: 'Er moet één pakket worden geselecteerd.',
    },
    info: {
      multipleArticles: 'Meerdere artikelen kunnen worden geselecteerd.',
    },
    step: {
      articles: 'Artikelen',
      callPlanOptions: 'Bel plan opties',
      customer: 'Klant',
      extraOptions: 'Extra opties',
      package: 'Pakket',
      phoneNumberDetails: 'Telefoonnummer(s)',
      summary: 'Overzicht',
      userDetails: 'Gebruiker(s)',
    },
  },

  billingFrequencyTerm,

  hint: {
    comment: 'Geef hier een opmerking op met bijv. speciale wensen of gemaakte afspraken.',
    reference:
      'Geef hier jouw eigen referentie op. Wij zorgen dat deze mee wordt gestuurd in communicatie over de bestelling.',
  },
  tip: {
    installationDate: 'Datum waarop de apparatuur bij de klant wordt geïnstalleerd.',
    deliveryDate: 'Datum waarop de apparatuur bij de klant wordt geactiveerd.',
  },
};

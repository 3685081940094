import { PartnerType } from '../../services/hubspot/partner-type.enum.ts';

const PartnerTypeMessages: { [key in PartnerType]: string } = {
  distributor: 'Distributeur',
  wholesale_internet: 'Wholesale internet',
  wholesale_isp: 'Wholesale ISP',
};

export const CompanyMessages = {
  details: 'Bedrijfsgegevens',

  coc: {
    hint: 'Alleen bedrijven met een postcode kunnen worden geselecteerd.',
    title: 'KVK-bedrijf',
  },

  communicationContacts: {
    edit: 'Bewerk communicatiecontacten',
    explanation: 'Contacten die in de CC van emails over je bestellingen worden toegevoegd',
    none: 'Geen communicatiecontacten ingesteld',
    title: 'Communicatiecontacten',
  },

  partnerType: PartnerTypeMessages,

  property: {
    brancheNumber: 'Branche-nummer',
    domain: 'Domeinnaam',
    name: 'Bedrijfsnaam',
    cocNumber: 'KVK-nummer',
    partnerType: 'Type partner',
    postalCode: 'Postcode',
  },
};
